.boldParaToolTipAndInput_container{
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 4%;
}

.bpttc_row1{
    width: 80%;
}


.bpttc_row2{
    width: 20%;
}