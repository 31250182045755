.BoldParaTTipIRange_main{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 3%;
}
.InputRange{
    width: 100%;
    align-items: center;
    display: flex;
}
.BoldParaTTipIRange_main .child1{
width: 100%;
margin-bottom: 3%;
}