.BlackandGreen_container{
    margin-bottom: 10%;
    margin-top: 12%;
    display: flex;
    justify-content: space-between;
    font-family: sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 28px;
letter-spacing: 0em;
text-align: left;
width: 80%;
margin: auto;
margin-top: 4%;    margin-bottom: 4%;



}
.BlackandGreen_container .child2{
    color: #61D6A4;
}