.calculatorHeader {
  position: relative;
  padding: 20px;
  width: 75%;
  max-width: 1400px;
  min-height: 400px;
  margin: 0 auto;
  margin-bottom: 32px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.calculatorHeader__bgimage {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  min-width: 100%;
  /* height: 600px; */
  /* min-height: 400px; */
}

.calculatorHeader__vector {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}

.calculatorHeader__content {
  color: #fff;
  /* position: relative;
  top: 50%; */
  /* transform: translateY(-50%); */
}

.calculatorHeader__content h1 {
  font-size: 48px;
}
@media (max-width: 1200px) {
  .calculatorHeader__content {
    max-width: 600px;
  }
}
