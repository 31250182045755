.main {
  display: flex;
  width: 75%;
  height: auto;
  margin: auto;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.main_row1 {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.main_row2 {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.main_row2_c1 {
  width: 100%;
}
.main_row2_c2 {
  width: 100%;
}
.main_row3 {
  width: 45%;
}

.main_row4 {
  width: 45%;
}
.main_row31 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.main-heading {
  width: 85%;
}
.main_icon {
  width: 8%;
  margin-left: 0;
}

@media (max-width: 768px) {
  .main {
    flex-direction: column;
  }

  .main_row1 {
    width: 100%;
  }
  .main_row2 {
    width: 100%;
  }

  .main_row3 {
    width: 100%;
  }
  .main_row4 {
    width: 100%;
  }
}
