.calMain {
  width: 80%;
  margin-top: 5%;
  margin: auto;
  margin-bottom: 64px;
  display: flex;
  font-family: sans-serif;
  flex-direction: column;

  background: #ffffff;
  box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
}
.col1cal {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  text-align: center;
  width: 100%;
  color: #ffffff;
  margin: auto;
  padding-bottom: 3%;
  padding-top: 3%;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;

  background-color: #61d6a4;
}
.caltext {
  width: 70%;
  margin: auto;
}
