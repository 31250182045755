.input_input {
  border: none;
  outline: none;
  text-align: center;
  /* margin: auto; */
  display: block;
  border: 2px solid #61d6a4;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.inputField {
  position: relative;
}

.inputField .preUnit {
  position: absolute;
  top: 50%;
  transform: translateY(-63%);
  left: 30%;
  font-weight: 700;
}

.inputField .postUnit {
  position: absolute;
  top: 50%;
  transform: translateY(-63%);
  right: 5%;
  font-weight: 700;
}
