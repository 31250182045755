.answerMain{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    margin-top: 4%;
}
.anscol1{ 
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 28px;
color: #000000;
}

.anscol2{ 
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    }