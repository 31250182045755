.heading_main {
  text-align: left;
  font-weight: 700;
  font-size: 36px;
  color: #61d6a4;
  font-family: sans-serif;
  font-style: normal;
  line-height: 55px;
  letter-spacing: 0em;

  margin-bottom: 2%;
}
