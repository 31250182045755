/* input {
  width: 100%;
  height: 8px;
  border: 2px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 50px;
  -webkit-appearance: none;
}

.slider::-webkit-slider-thumb {
  border: solid 7px #61D6A4;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  -webkit-appearance: none;
  margin-top: -4px;
  cursor: pointer;
}
/* 
.slider::-webkit-slider-thumb .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #FFFFFF transparent transparent transparent;
} */
/* 


.slider::-moz-range-thumb {
  border: solid 7px #61D6A4;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  -webkit-appearance: none;
  cursor: pointer;

}

:root {
  --webkitProgressPercent: 100%;
}

.slider::-webkit-slider-runnable-track {
  background-image: linear-gradient(90deg, #61D6A4 var(--webkitProgressPercent), #E5E5E5 var(--webkitProgressPercent));
  border-radius: 50px;
  height: 8px;
} */

/* .apptooltip {
  width: 390px;

} */
.thumb::-webkit-slider-thumb {
  background: #61d6a4;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  -webkit-appearance: none;
  cursor: pointer;
}

.slider {
  position: relative;
  width: 100%;
  border-radius: 5px;
}
.slider__track {
  position: absolute;
  height: 8px;
  width: 100%;
  background-color: #e5e5e5;

  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 50px;
}

.slider__left-value {
  position: absolute;
  border: 2px solid #61d6a4;
  padding: 0.5% 3% 0.5% 3%;
  border-radius: 10px;
  margin-top: -10%;
  background-color: #61d6a4;
  margin-left: -5%;
  color: #ffffff;
}

.thumb {
  -webkit-appearance: none;
  height: 0px;
  width: 100%;
  position: relative;
  bottom: 12px;
}

.slider__range {
  position: absolute;
  background-color: #61d6a4;
  height: 8px;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 50px;
}
