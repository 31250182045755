.InputRange_Container{
display: flex;
align-items: center;
width: 100%;
margin-bottom: 1%;
justify-content: space-between;
}
.inputRange_child1{
    width:70%;
}
.inputRange_child2{
    width: 20%;
}