.boldParaAndToolTip_container{
display: flex;
flex-direction: column;
width: 100%;

}
.boldParaAndToolTip_container .child1{
display: flex;
margin-bottom: 2%;
margin-right:auto;


}
.child1 Tooltip{
   margin-top: -7px;
   margin-left: 2px;
   cursor: pointer; 
}